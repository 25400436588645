.Toastify__toast.Toastify__toast-theme--light.Toastify__toast--error {
  background: #f15a28;
  color: #fff;
}
.Toastify__toast.Toastify__toast-theme--light.Toastify__toast--success {
  background: #a394bf;
  color: #fff;
}

.Toastify__close-button.Toastify__close-button--light {
  color: #f4f4f4;
}

.Toastify__toast-icon {
  display: none;
}
