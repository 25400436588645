@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'OpenSansRegular';
  src: local('OpenSansRegular'), url('./fonts/OpenSans/OpenSans-Regular.ttf') format('truetype');
}
html,
body,
#root {
  height: 100%;
  color: #333;
}

body {
  margin: 0;
  font-family: 'OpenSansRegular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.menu-icon:hover svg path,
.menu-icon.active svg path {
  stroke: #60daec;
}
.menu-icon.active {
  color: #60daec;
}

.sidenav {
  transition: 0.5s;
}
