/* Amplify custom login styles */
[data-amplify-authenticator][data-variation='modal'] {
  background-color: #fff;
}

[data-amplify-authenticator] [data-amplify-router],
[data-amplify-authenticator] [data-amplify-router] > div {
  box-shadow: none;
  border: none;
  text-align: center;
}

[data-amplify-authenticator] [data-amplify-container] {
  width: 100%;
}

[data-amplify-authenticator] [data-amplify-router] > div > div:first-child {
  display: none;
}
[data-amplify-router-content],
[data-amplify-router] {
  padding: 24px;
  padding-top: 36px;
}
[data-amplify-router] {
  background-color: white;
}

[data-amplify-authenticator] [data-amplify-form] {
  padding: 0;
}

[data-amplify-theme],
[data-amplify-authenticator],
[data-amplify-container] {
  height: 100%;
}
legend {
  display: none;
}

.amplify-label.amplify-visually-hidden {
  font-size: 14px;
  margin-left: 16px;
  color: #69d393;
}
.amplify-flex.amplify-field.amplify-textfield {
  margin-bottom: 12px;
  text-align: left;
}

.amplify-input,
.amplify-button.amplify-field-group__control.amplify-field__show-password,
.amplify-input:focus,
.amplify-button.amplify-field-group__control.amplify-field__show-password:focus {
  border: 1px solid #69d393;
  box-shadow: none;
}
.amplify-button.amplify-field-group__control.amplify-field__show-password .amplify-visually-hidden {
  display: none;
}
.amplify-input,
.amplify-button.amplify-field-group__control.amplify-field__show-password {
  background: #f4f4f4;
  color: #333;
  border: 0;
  border-radius: 8px;
  padding: 12px 16px;
  width: 100%;
}
.amplify-flex.amplify-field.amplify-textfield.amplify-passwordfield
  > .amplify-flex.amplify-field-group.amplify-field-group--horizontal
  > .amplify-field-group__field-wrapper.amplify-field-group__field-wrapper--horizontal
  > .amplify-input.amplify-field-group__control {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.amplify-flex.amplify-field.amplify-textfield.amplify-passwordfield
  > .amplify-flex.amplify-field-group.amplify-field-group--horizontal
  > .amplify-field-group__outer-end
  > .amplify-button.amplify-field-group__control.amplify-field__show-password {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

[data-amplify-authenticator] [data-amplify-form] .amplify-button.amplify-button--fullwidth,
.amplify-button.amplify-field-group__control.amplify-button--primary {
  background-color: #69d393;
  color: #fff;
  font-weight: 600;
  border-radius: 32px;
  padding: 12px 24px;
  width: 80%;
  margin: 24px auto 36px auto;
  transition: 0.3s;
}
.amplify-button.amplify-field-group__control.amplify-button--primary:hover,
.amplify-button.amplify-field-group__control.amplify-button--primary:focus {
  background: #d3efff;
}

.amplify-button.amplify-field-group__control.amplify-button--primary:disabled {
  background: #cccccc;
}

.amplify-icon svg {
  color: #999;
}
.amplify-alert.amplify-alert--error {
  background-color: transparent;
  display: flex;
}
.amplify-alert.amplify-alert--error .amplify-icon.amplify-alert__icon svg,
.amplify-alert.amplify-alert--error .amplify-alert__body,
.amplify-alert.amplify-alert--error button svg {
  color: #f15a28;
}

.amplify-heading.amplify-heading--3 {
  font-size: 22px;
  margin-bottom: 22px;
}

.amplify-button--small {
  color: #69d393;
}

.amplify-button--small:hover {
  background-color: transparent;
  color: #69d393;
}

.amplify-flex.amplify-field-group.amplify-field-group--horizontal {
  display: flex;
  margin-top: 6px;
  margin-bottom: 24px;
}
.amplify-field-group__field-wrapper.amplify-field-group__field-wrapper--horizontal {
  width: 100%;
}

.amplify-loader {
  display: none;
}
.amplify-button.amplify-field-group__control.amplify-button--primary.amplify-button--disabled.amplify-button--loading.amplify-button--fullwidth {
  background-color: #999;
  color: #fff;
}

.bgLogin {
  background-image: url('../icons/bg-login.svg');
  background-repeat: repeat-x;
  height: 265px;
  width: 100%;
  bottom: -60px;
}

@media only screen and (min-width: 600px) {
  [data-amplify-authenticator] [data-amplify-container] {
    padding: 0;
  }
  [data-amplify-router-content],
  [data-amplify-authenticator-resetpassword],
  [data-amplify-authenticator-confirmresetpassword],
  [data-amplify-authenticator-forcenewpassword] {
    width: 400px;
    margin: 0 auto;
  }
}

/* end login styles */
